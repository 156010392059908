import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAuthProps } from 'hooks';
import useModal from 'hooks/useModal';
import AuthModal from 'components/auth/AuthModal';
import { AuthSelectors } from 'store/selectors';
import { AuthActions } from 'store/actions';
import NudgeToLoginPopover from './NudgeToLoginPopover';

const withNudgeToLoginPopover = <P,>(Component: React.ComponentType<React.PropsWithChildren<P>>) => {
  const NudgeToLoginPopoverWrapper: React.FC<React.PropsWithChildren<P>> = (props) => {
    const [showAuthModal] = useModal(AuthModal);
    const [open, setOpen] = useState(false);
    const { isAuthenticated } = useAuthProps();
    const dispatch = useDispatch();

    const linkState = useSelector(AuthSelectors.socialLinksState);

    useEffect(() => {
      if (!linkState.result) {
        dispatch(AuthActions.socialLinks.reset());
        dispatch(AuthActions.socialLinks.request());
      }
    }, [dispatch, linkState?.result]);

    useEffect(() => {
      if (!sessionStorage.getItem('shownNudgeToLoginPopover') && !isAuthenticated) {
        sessionStorage.setItem('shownNudgeToLoginPopover', String(true));
        setOpen(true);
      } else if (isAuthenticated) setOpen(false);
    }, [isAuthenticated]);

    const onGoogleButtonClick = () => {
      window.location.href = linkState?.result?.auth_url;
    };

    return (
      <>
        <Component {...props} onCloseNudgeToLoginPopover={() => setOpen(false)} />
        {open && (
          <NudgeToLoginPopover
            getStarted={() => showAuthModal({ variant: 'createAccount' })}
            loginEmail={() => showAuthModal({ variant: 'login' })}
            loginGoogle={onGoogleButtonClick}
            onClose={() => setOpen(false)}
          />
        )}
      </>
    );
  };
  return NudgeToLoginPopoverWrapper;
};

export default withNudgeToLoginPopover;
